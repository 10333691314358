import jQuery from 'jquery';

// Select Helper
// -------------
// This is a select helper for the Materialize Select field to add
// a class to the field wrapper, if an option is selected.

function optionSelectedHandler($select) {
  const $field = $select.closest('.form-field.field-select');

  if ($field.length === 0) {
    return;
  }

  if ($select.find(':selected').val()) {
    $field.addClass('selected');
  } else {
    $field.removeClass('selected');
  }
}

jQuery(function ($) {
  // Go!
  const $selectFields = $('.form-field.field-select');

  $selectFields.change(function () {
    optionSelectedHandler($(this));
  });

  // Run handler once for each select
  $selectFields.each(function () {
    optionSelectedHandler($(this));
  });
});
